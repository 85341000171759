// import { marked } from 'marked';
import { Marked } from "marked";
import { markedHighlight } from "marked-highlight";
import hljs from 'highlight.js';
import { isState, Value } from 'terraconnect-state';
import { Component, ComponentFN } from 'terraconnect-ui/*';
import { jsx } from 'terraconnect-ui/jsx-runtime';

const marked = new Marked(
  markedHighlight({
    langPrefix: 'hljs language-',
    highlight(code, lang, info) {
      const language = hljs.getLanguage(lang) ? lang : 'plaintext';
      return hljs.highlight(code, { language }).value;
    }
  })
);


type MarkdownProps = {
  content?: string;
};

const Markdown: ComponentFN<MarkdownProps> = ({ content, children }) => {
  let markdown = (isState(content) ? content[Value] : content) ?? "";
  children?.[Value]?.forEach(_ => {
    if(typeof _ != "string") return;
    markdown += _ + "\n"
  });
  let div = jsx("div", {}) as unknown as HTMLDivElement;
  if (markdown == null) return <></>;
  div.innerHTML = marked.parse(markdown, { async: false }) as string;
  return <>{Array.from(div.children)}</>;
}

export default Markdown as Component<MarkdownProps>;