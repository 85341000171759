import { createState, PreserveState, Value } from 'terraconnect-state';
import * as TerraconnectUI from 'terraconnect-ui';
import { assign, path as pagePath } from './Router';

const RouteNotFound: TerraconnectUI.ComponentFN = function ({ children }) {
  (this as any).visible = createState(false);
  (this as any).visible[PreserveState] = true;

  let clickRoute = function (this: HTMLAnchorElement, event: MouseEvent) {
    if(this.target == "_black") return;
    assign(this.href);
    if(this.href.replace(window.location.origin, "").split('#')[0] == pagePath[Value]) return;
    event.stopPropagation();
    event.preventDefault();
  };

  this.addEventListener('mount', ({ target }) => {
    if (target == null) return; target
    let element = target as HTMLAnchorElement;
    if (element.tagName == "A") {
      element.removeEventListener('click', clickRoute.bind(element));
      element.addEventListener('click', clickRoute.bind(element));
    } else {
      element.querySelectorAll('a').forEach(element => {
        element.removeEventListener('click', clickRoute.bind(element));
        element.addEventListener('click', clickRoute.bind(element));
      })
    }
  }, {});

  return (
    <>
      {children}
    </>
  );
}

export default RouteNotFound as TerraconnectUI.Component;
