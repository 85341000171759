import { Component, ComponentFN } from 'terraconnect-ui';
import './index.scss';
import Router, { path } from 'Components/Router/Router';
import Route from 'Components/Router/Route';
import RouteNotFound from 'Components/Router/RouteNotFound';
import { createState } from 'terraconnect-state';
import PageRoot from './PageRoot';

const App: Component = () => {
    return (
        <Router>
            <Route path="/" component={PageRoot} />
            <RouteNotFound>
                <div className="container">
                    <div className="navbar-container">
                        <div className="navbar">
                            <a href="/#register"><span>Create Account</span></a>
                            <a href="/#download"><span>Download</span></a>
                            <a href="/#forum"><span>Forum</span></a>
                            <a href="/#information"><span>Information</span></a>
                            <a href="/login"><span>Login</span></a>
                        </div>
                    </div>
                    <div className="logo-container">
                        <div className="logos">
                            <img className="left" src="/AlteGarde_Logo.png" />
                            <img className="right" src="/WOW_Logo.png" />
                        </div>
                    </div>
                    <div className="content-container">
                        <div className="content">
                            <span className="title">404 Not Found</span>
                            <br />
                            <span>{createState((path: string) => `Can not find page "${path.slice(1, -1)}"`, [path])}</span>
                        </div>
                    </div>
                </div>
            </RouteNotFound>
        </Router>
    );
};

export default App;