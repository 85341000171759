import { ChildModified, createState, PreserveState, Value } from 'terraconnect-state';
import { jsx } from 'terraconnect-ui/jsx-runtime';
import { assign, path as pagePath } from './Router';
import { children, Component, ComponentFN } from 'terraconnect-ui';

export type RouteProps<T extends Component<K>, K> = {
  path: string;
  component: T;
} & K;

const Route: ComponentFN<RouteProps<any, any>> = function (props) {
  let { component, path, ...args } = props;
  if (typeof this.props.path[Value] == "string" && !this.props.path[Value].endsWith("/"))
    this.props.path[Value] += "/";
  (this as any).visible = createState(false);
  (this as any).visible[PreserveState] = true;

  let clickRoute = function (this: HTMLAnchorElement, event: MouseEvent) {
    if(this.target == "_black") return;
    assign(this.href);
    if(this.href.replace(window.location.origin, "").split('#')[0] == pagePath[Value]) return;
    event.stopPropagation();
    event.preventDefault();
  };

  this.addEventListener('mount', ({ target }) => {
    if (target == null) return; target
    let element = target as HTMLAnchorElement;
    if (element.tagName == "A") {
      element.removeEventListener('click', clickRoute.bind(element));
      element.addEventListener('click', clickRoute.bind(element));
    } else {
      element.querySelectorAll('a').forEach(element => {
        element.removeEventListener('click', clickRoute.bind(element));
        element.addEventListener('click', clickRoute.bind(element));
      })
    }
  }, {});

  let cache: null | children = null;
  return createState((visible: boolean, component: Component) => {
    if (!visible) return <></>
    if (cache == null)
      cache = jsx(component, args);
    return [cache];
  }, [(this as any).visible, component]);
}

export default Route as unknown as Component<RouteProps<any, any>>;
