import Markdown from 'Components/Markdown/Markdown';
import { Component, ComponentFN } from 'terraconnect-ui';
// @ts-ignore
import willkommen from './willkommen.txt';
// @ts-ignore
import download from './download.txt';
// @ts-ignore
import information from './information.txt';

const PageRoot: ComponentFN = ({ children }) => {
    const createAccount: JSX.IntrinsicElements['form']['onSubmit'] = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const data = new FormData(e.target as HTMLFormElement);
        let email = data.get("email");
        let username = data.get("username");
        let password = data.get("password");
        console.log({ email, username, password });
        alert(`Account ${username} created`);
    };

    let password = <input type="password" placeholder="Password" name="password" id='password' required onChange={validatePassword} />;
    let repeat_password = <input type="password" placeholder="Repeat Password" name="repeat_password" id='repeat_password' required onChange={validatePassword} />;

    function validatePassword() {
        if (password.value != repeat_password.value) {
            repeat_password.setCustomValidity("Passwords Don't Match");
        } else {
            repeat_password.setCustomValidity('');
        }
    };


    return (
        <div className="container">
            <div className="navbar-container">
                <div className="navbar">
                    <a href="#register"><span>Create Account</span></a>
                    <a href="#download"><span>Download</span></a>
                    <a href="#forum"><span>Forum</span></a>
                    <a href="#information"><span>Information</span></a>
                    <a href="/login"><span>Login</span></a>
                </div>
            </div>
            <div className="logo-container">
                <div className="logos">
                    <img className="left" src="/AlteGarde_Logo.png" />
                    <img className="right" src="/WOW_Logo.png" />
                </div>
            </div>
            <div className="content-container">
                <div className="content">
                    <img src="/WoW-Wotlk-Classic-Todesritter-buffed.png" />
                </div>
            </div>
            <div className="content-container">
                <div className="content">
                    <span className="title">Willkommen</span>
                    <div className="text">
                        <Markdown content={willkommen} />
                    </div>
                </div>
            </div>
            <div className="content-container">
                <div className="content" id="register">
                    <span className="title">Create a new account</span>
                    <form className="create-account-form" onSubmit={createAccount}>
                        <div className="group">
                            <input type="input" placeholder="Username" name="username" id='username' required />
                            <label htmlFor="username">Username</label>
                        </div>
                        <div className="group">
                            <input type="input" placeholder="Email" name="email" id='email' required />
                            <label htmlFor="email">Email</label>
                        </div>
                        <div className="group">
                            {password}
                            <label htmlFor="password">Password</label>
                        </div>
                        <div className="group">
                            {repeat_password}
                            <label htmlFor="repeat_password">Repeat Password</label>
                        </div>
                        <button type="submit" className="primary">Create</button>
                    </form>
                </div>
            </div>
            <div className="content-container" id="download">
                <div className="content">
                    <span className="title">Download</span>
                    <div className="text">
                        <Markdown content={download} />
                    </div>
                </div>
            </div>
            <div className="content-container" id="forum">
                <div className="content">
                    <iframe src="https://e.widgetbot.io/channels/1228357757007298560/1267931794951372811" allow="clipboard-write; fullscreen" height="600px" width="100%"></iframe>
                </div>
            </div>
            <div className="content-container" id="information">
                <div className="content">
                    <span className="title">Information</span>
                    <div className="text"><br></br>
                        <Markdown content={information} />
                    </div>
                </div>
            </div>
            <div className="content-container">
                <div className="content">
                    <img src="/WoWScrnShot_121219_003056.png" />
                </div>
            </div>
        </div>
    );
};

export default PageRoot as Component;